<template>
  <div class="mobile-list">
    <searchBox placeholder="请输入关键字" :onTextChanged="textChanged" :onCancel="cancel" :onFilterClicked="filterClicked" :onSearchClicked="searchClicked" :msg="enterkey"></searchBox>

    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <itemBox v-for="(item, index) in items" :code="item.model.code" :stateName="taskState(item.model.state)" :stateColor="getStatusColor(item.model.state)" :name="item.model.name" :user="item.model.user_name" :time="item.model.add_time" :exts="[item.model.project_name]" :key="index"
          @click.native="itemClick(item)" />
        
      </orderScroll>
    </div>

    <searchDrawer ref="searchDrawer" v-if="showingFilter" :onBgClicked="filterBgClicked" :onReset="resetFilter" :onConfirm="confirmFilter">
      <taskFilter ref="filter" :filterOption="filterOption" />
    </searchDrawer>
  </div>
</template>

<script>
import { GetTask } from "@/api";
import { taskState } from "@/utils/state.js";
import { loadListAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      },
      enterkey: [],
      keyword: "",
    };
  },
  methods: {
    taskState: taskState,
    getStatusColor: function (state) {
      switch (state) {
        case 0:
          return "grey-font-color";
        case 1:
          return "orange-font-color";
        case 2:
          return "red-font-color";
        case 3:
          return "link-font-color";
        default:
          return "green-font-color";
      }
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    itemClick: function (item) {
      this.$router.push({
        name: "mTaskDetail",
        query: {
          id: item.model.id,
        },
      });
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    filterClicked: function () {
      this.showingFilter = true;
      this.$nextTick(() => {
        this.$refs.searchDrawer.show();
      });
    },
    filterBgClicked: function () {
      this.showingFilter = false;
    },
    resetFilter: function () {
      this.filterOption = {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      };
      this.showingFilter = false;
      this.firstPage();
    },
    confirmFilter: function () {
      this.filterOption = this.$refs.filter.getOption();
      this.showingFilter = false;
      this.firstPage();
    },
    searchClicked: function (keywords) {
      this.keyword = keywords;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      loadListAction(this, GetTask, {
        sTime: this.filterOption.startTime,
        eTime: this.filterOption.endTime,
        state: this.filterOption.orderStatusKey,
        keyword: this.keyword,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          orderBy: "time desc",
        }
      }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.list.length > 0) {
          this.items = this.items.concat(data.list);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    },
  },
  mounted: function () {
    this.firstPage();
  },
};
</script>
